import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Aboutus from '../pages/AboutUs';
import  ContactUs from '../pages/ContactUs';
import CorporateTraining from '../pages/CorporateTraining';
import CourseDetails from '../pages/CourseDetails';
import Login from '../pages/admin/Login';
import CourseList from '../pages/admin/CourseList';
import AddNewCourse from '../pages/admin/AddNewCourse';
import EditCourse from '../pages/admin/EditCourse';
import Trainers from '../pages/Trainers';
import CourseCategories from '../pages/admin/CourseCategories';
import Locations from '../pages/admin/Locations';
import Coupons from '../pages/admin/Coupons';
import TalkToExperts from '../pages/admin/TalkToExperts';
import DropQuery from '../pages/admin/DropQuery';
import QuotationRequest from '../pages/admin/QuotationRequest';
import Orders from '../pages/admin/Orders';
import Batches from '../pages/admin/Batches';
import Cart from '../pages/Cart';
import ThankYou from '../pages/ThankYou';
import RegistrationsList from '../pages/admin/RegistrationsList';
import ConsultationList from '../pages/admin/ConsultationList';
import InstructorsList from '../pages/admin/InstructorsList';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import RefundPolicy from '../pages/RefundPolicy';
import Pmp from '../pages/seo/Pmp';
import AllCourses from '../pages/AllCourses';
import HomepageCourses from '../pages/admin/HomepageCourses';
import CouponsOffersList from '../pages/admin/CouponsOffersList';

const AllRoutes = () => {
    return (
        <Router basename={'/'} >
            <Routes>
                {/* <Route exact path="/" element={<Pmp />} /> */}
                <Route exact path="/" element={<Home />} />
                <Route exact path="/home" element={<Home />} />
                <Route exact path="/about-us" element={<Aboutus />} />
                <Route exact path='/contact-us' element={<ContactUs />} />
                <Route exact path='/corporate-training' element={<CorporateTraining />} />
                <Route exact path='/courses/:categoryslug/:courseslug' element={<CourseDetails />} />
                <Route exact path='/trainers' element={<Trainers />} />
                <Route exact path='/cart' element={<Cart />} />
                <Route exact path='/thankyou' element={<ThankYou />} />
                <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route exact path='/refund-policy' element={<RefundPolicy />} />
                <Route exact path='/all-courses' element={<AllCourses />} />


                <Route exact path='/newadminlogin' element={<Login />} />
                <Route path='/webadmin/home' element={<CourseList />} />
                <Route path='/webadmin/courselist' element={<CourseList />} />
                <Route path='/webadmin/addnewcourse' element={<AddNewCourse />} />
                <Route path='/webadmin/editcourse/:id' element={<EditCourse />} />
                <Route path='/webadmin/categorylist' element={<CourseCategories />} />
                <Route path='/webadmin/locationslist' element={<Locations />} />
                <Route path='/webadmin/coupons' element={<Coupons />} />
                <Route path='/webadmin/talktoexperts' element={<TalkToExperts />} />
                <Route path='/webadmin/dropaquery' element={<DropQuery />} />
                <Route path='/webadmin/quotationrequest' element={<QuotationRequest />} />
                <Route path='/webadmin/orders' element={<Orders />} />
                <Route path='/webadmin/batcheslist' element={<Batches />} />
                <Route path='/webadmin/registrationslist' element={<RegistrationsList />} />
                <Route path='/webadmin/consultationrequest' element={<ConsultationList />} />
                <Route path='/webadmin/instructorslist' element={<InstructorsList />} />
                <Route path='/webadmin/homepageCourses' element={<HomepageCourses />} />
                <Route path='/webadmin/couponsenquirelist' element={<CouponsOffersList />} />
            </Routes>
        </Router>
    )
}

export default AllRoutes;